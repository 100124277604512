import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Button, Center, Heading, Image, SimpleGrid, Text, VStack } from "@chakra-ui/react";

const ComoFunciona = () => {
  return (
    <Center id="como-funciona" bg="#FCFCFC">
      <Box maxW="1300px" w="90%" py="100px">
        <Heading color="main.500" size="2xl" textAlign="center" mb="60px">
          Como funciona
        </Heading>
        <SimpleGrid columns={[1, 3]} spacing="100px">
          <VStack pt={{ base: "0", lg: "100px" }} position="relative">
            <Image
              alt="Seta superior para a direita"
              src={"/assets/imgs/seta-1.webp"}
              w="200px"
              position="absolute"
              top="50px"
              right="-100px"
              display={{ base: "none", lg: "block" }}
            />
            <Image alt="Fazendas solares" src={"assets/imgs/placa-e-sol.webp"} />
            <Text fontSize="lg" color="gray.600" textAlign="center">
              Nossas fazendas solares produzem energia limpa e renovável.
            </Text>
          </VStack>
          <VStack position="relative">
            <Image alt="Torre de transmissão" src={"assets/imgs/torre-transmissao.webp"} />
            <Text fontSize="lg" color="gray.600" textAlign="center">
              Essa energia é enviada para a rede de distribuição da CEMIG.
            </Text>
            <Image
              alt="Seta inferior para a direita"
              src={"assets/imgs/seta-2.webp"}
              w="200px"
              position="absolute"
              bottom="20px"
              right="-100px"
              display={{ base: "none", lg: "block" }}
            />
          </VStack>
          <VStack pt={{ base: "0", lg: "100px" }}>
            <Image alt="Unidade consumidora" src="/assets/imgs/casa.webp" />
            <Text fontSize="lg" color="gray.600" textAlign="center">
              E chega em sua casa em forma de créditos de energia, que se transformam em desconto na sua conta.
            </Text>
          </VStack>
        </SimpleGrid>
        <Center mt="40px">
          <Button
            aria-label="Quero economizar"
            as={RouterLink}
            to="/quero-economizar"
            size="lg"
            w={{ base: "100%", lg: "auto" }}
            colorScheme="main"
          >
            QUERO ECONOMIZAR!
          </Button>
        </Center>
      </Box>
    </Center>
  );
};

export default ComoFunciona;
